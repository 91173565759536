import { AmenitiesDataOptionsTypes, DataOptionsTypes } from "./types";

export const operation_type_id:DataOptionsTypes[] = [
    {
        text: 'Venta',
        value: 1,
        countries:['UY','PY','BO','PE']
    },
    {
        text: 'Alquiler',
        value: 2,
        countries:['UY','PY','BO','PE']
    },
    {
        text: 'Alquiler Temporal',
        value: 4,
        countries:['UY','PY','BO','PE']
    },
    {
        text: 'Anticrético',
        value: 5,
        countries:['BO']
    }
]

export const property_type_id:DataOptionsTypes[] = [
    {
        text: 'Casa',
        value: 1,
        countries: ['UY','PY','BO','PE','CE3']
    },
    {
        text: 'Apartamento',
        value: 2,
        countries: ['CE3','UY']
    },
    {
        text: 'Departamento',
        value: 2,
        countries: ['PY','BO','PE']
    },
    {
        text: 'Terreno',
        value: 3,
        countries: ['UY','PY','PE']
    },
    {
        text:'Chacra',
        value: 6,
        countries:['CE3']
    },
    {
        text:'Lote o Terreno',
        value: 3,
        countries:['BO']
    },
    {
        text: 'Local Comercial',
        value: 4,
        countries: ['UY','PY','BO','PE']
    },
    {
        text: 'Oficina',
        value: 5,
        countries: ['UY','PY','BO','PE']
    },    
    {
        text: 'Chacra o Campo',
        value: 6,
        countries: ['UY','PY','PE']
    },
    {
        text: 'Quinta o Campo',
        value: 6,
        countries: ['BO']
    },
    {
        text: 'Garaje o Cochera',
        value: 8,
        countries: ['UY','PY','BO','PE']
    },
    {
        text: 'Negocio Especial',
        value: 9,
        countries: ['UY','PY','BO','PE']
    },
    {
        text: 'Edificio',
        value: 10,
        countries: ['UY','PE']
    },
    {
        text: 'Edificio u Hotel',
        value: 10,
        countries: ['PY']
    },
    {
        text: 'Hotel',
        value: 11,
        countries: ['UY','PE']
    },
    {
        text: 'Local industrial o galpón',
        value: 12,
        countries: ['UY','BO','PE']
    },
    {
        text:'Tinglado o Depósito',
        value:12,
        countries:['PY']
    },
    {
        text: 'Otro',
        value: 13,
        countries: ['UY','PY','BO','PE']
    },
    {
        text:'Dúplex',
        value:14,
        countries:['PY']
    },
    {
        text:'Condominio',
        value:14,
        countries:['BO']
    },
    {
        text:'Habitación',
        value:15,
        countries:['BO']
    }
]

export const bedrooms:DataOptionsTypes[] = [
    {
        smallText: 'Mono.',
        text: 'Monoambiente',
        value: 0
    },
    {
        smallText: '1',
        text: '1 dormitorios',
        value: 1
    },
    {
        smallText: '2',
        text: '2 dormitorios',
        value: 2        
    },
    {
        smallText: '3',
        text: '3 dormitorios',
        value: 3
    },
    {
        smallText: '4',
        text: '4 dormitorios',
        value: 4
    }
    // {
    //     smallText: '5',
    //     text: '5 dormitorios',
    //     value: 5
    // }
]

export const rooms:DataOptionsTypes[] = [
    {
        smallText: '1',
        text: '1 Ambiente',
        value: 1
    },
    {
        smallText: '2',
        text: '2 Ambientes',
        value: 2        
    },
    {
        smallText: '3',
        text: '3 Ambientes',
        value: 3
    },
    {
        smallText: '4',
        text: '4 Ambientes',
        value: 4
    },
    {
        smallText: '5',
        text: '5 Ambientes',
        value: 5
    },
    {
        smallText: '6+',
        text: '6+ Ambientes',
        value: 6
    }
]

export const floors:DataOptionsTypes[] = [
    {
        smallText: 'Planta Baja',
        text:'Planta Baja',
        value: 0
    },
    {
        smallText: 'Penthouse',
        text:'Penthouse',
        value: 4
    }
]

export const currencyType:DataOptionsTypes[] = [    
    {
        text: '$ - Pesos Uruguayos',
        smallText: '$',
        countries: ['UY'],
        value: 2
    },
    {
        text: 'Gs. - Guaraníes',
        smallText: 'Gs.',
        countries: ['PY'],
        value: 3
    },
    {
        text: 'S/ - Soles',
        smallText: 'S/',
        countries: ['PE'],
        value: 6
    },
    {
        text: 'U$S - Dólares Americanos',
        smallText: 'U$S',
        countries: ['UY','PY','BO','PE','CE3'],
        value: 1
    }
]

export const bathrooms:DataOptionsTypes[] = [
    {
        smallText: '1',
        text:'1 baño',
        value: 1
    },
    {
        smallText: '2',
        text:'2 baños',
        value: 2
    },
    {
        smallText: '3',
        text:'3 baños',
        value: 3
    }
]

export const surfaceRange:DataOptionsTypes[] = [
    {
        text: 'Edificados',
        value:'edificados'
    },
    {
        text: 'Totales',
        value:'totales'
    },    
]

export const amenities:AmenitiesDataOptionsTypes[]=[
    {
        value: 1,
        text: "Balcón",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/YardIcon/web")),
    },
    {
        value: 2,
        text: "Box",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/BoxIcon/web")),
    },
    {
        value: 3,
        text: "Calefacción Individual",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/HeatingIcon/web")),
    },
    {
        value: 4,
        text: "Calefón",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/CalefonIcon/web")),
    },
    {
        value: 5,
        text: "Cochera",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/GarageIcon/web")),
    },
    {
        value: 6,
        text: "Depósito",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/DepositIcon/web")),
    },
    {
        value: 7,
        text: "Dormitorio de servicio",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/ServiceRoomIcon/web")),
    },
    {
        value: 8,
        text: "Estufa a Leña",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/WoodStoveIcon/web")),
    },
    {
        value: 10,
        text: "Jacuzzi",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/JacuzziIcon/web")),
    },
    {
        value: 11,
        text: "Línea Blanca",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/WhiteLineIcon/web")),
    },
    {
        value: 12,
        text: "Losa Radiante",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/LosaRadianteIcon/web")),
    },
    {
        value: 13,
        text: "Parrillero",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/GrillIcon/web")),
    },
    {
        value: 14,
        text: "Placard en cocina",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PlacardKitchenIcon/web")),
    },
    {
        value: 15,
        text: "Placard en dormitorio",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PlacardBedroomIcon/web")),
    },
    {
        value: 16,
        text: "Terraza",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/TerraceIcon/web")),
    },
    {
        value: 17,
        text: "Terraza Lavadero",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/TerraceLaundryIcon/web")),
    },
    {
        value: 18,
        text: "Vestidor",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/DressingRoomIcon/web")),
    },
    {
        value: 20,
        text: "Ascensor",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/ElevatorIcon/web")),
    },
    {
        value: 21,
        text: "Barbacoa",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/BarbacueIcon/web")),
    },
    {
        value: 22,
        text: "Bungalow",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/BungalowIcon/web")),
    },
    {
        value: 23,
        text: "GYM",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/GymIcon/web")),
    },
    {
        value: 25,
        text: "Lavandería",
        grupo: "Comodidad del edificio",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/LaundryIcon/web")),
    },
    {
        value: 27,
        text: "Piscina",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PoolIcon/web")),
    },
    {
        value: 28,
        text: "Playroom",
        grupo: "Comodidad del edificio",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PlayroomIcon/web")),
    },
    {
        value: 29,
        text: "Salón de uso común",
        grupo: "Comodidad del edificio",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/CommonUseRoomIcon/web")),
    },
    {
        value: 30,
        text: "Spa",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/SpaIcon/web")),
    },
    {
        value: 31,
        text: "WiFi",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/WifiIcon/web")),
    },
    {
        value: 32,
        text: "Agua Caliente Central",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/HotWaterIcon/web")),
    },
    {
        value: 33,
        text: "Calefacción Central",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/HeatingIcon/web")),
    },
    {
        value: 34,
        text: "Instalación de TV cable",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/TVIcon/web")),
    },
    {
        value: 35,
        text: "Previsión A.A.",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/ACICon/web")),
    },
    {
        value: 36,
        text: "Aire Acondicionado",
        grupo: "Principales",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/ACICon/web")),
    },
    {
        value: 37,
        text: "Gas por Cañería",
        grupo: "Comodidades",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PipedGasIcon/web")),
    },
    {
        value: 40,
        text: "Altillo",
        grupo: "Espacios",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/AltilloIcon/web")),
    },
    {
        value: 45,
        text: "Calefacción",
        grupo: "Comodidades",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/HeatingIcon/web")),
    },
    {
        value: 65,
        text: "Sótano",
        grupo: "Espacios",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/BasementIcon/web")),
    },
    {
        value: 69,
        text: "Amueblada",
        grupo: "Principales",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/FurnitureIcon/web")),
    },
    {
        value: 70,
        text: "Jardín",
        grupo: "Espacios",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/GardenIcon/web")),
    },
    {
        value: 72,
        text: "Patio",
        grupo: "Espacios",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/YardIcon/web")),
    },
    {
        value: 74,
        text: "Lavadero",
        grupo: "Comodidades",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/LaundryIcon/web")),
    },
    {
        value: 76,
        text: "Sauna",
        grupo: "Principales",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/SaunaIcon/web")),
    },
    {
        value: 78,
        text: "Solárium",
        grupo: "Espacios",
        IDtipos: 2,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/SolariumIcon/web")),
    },
    {
        value: 216,
        text: "Walk-in Closet",
        grupo: "Principales",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/WalkinClosetIcon/web")),
    },
    {
        value: 218,
        text: "Living comedor",
        grupo: "Espacios",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/LivingDinningIcon/web")),
    },
    {
        value: 220,
        text: "Piso porcelanato",
        grupo: "Principales",
        IDtipos: 1,
        IDoperaciones: null,
        icon: async () => (await import("shared-components/Components/CustomIcons/PorcelainFloorIcon/web")),
    },
    {
        value: 222,
        text: "Se aceptan mascotas",
        grupo: "Extras",
        IDtipos: 1,
        IDoperaciones: 4,
        icon: async () => (await import("shared-components/Components/CustomIcons/PetFriendlyIcon/web")),
    },
    {
        value: 225,
        text: "Se aceptan grupos de jóvenes",
        grupo: "Extras",
        IDtipos: 1,
        IDoperaciones: 4,
        icon: async () => (await import("shared-components/Components/CustomIcons/PeopleIcon/web")),
    }
]

export const propertyState:DataOptionsTypes[] = [
    {
        text: 'En Pozo',
        value:9
    },
    {
        text: 'En Construcción',
        value:8
    },
    {
        text:'A estrenar',
        value:1
    },
    {
        text:'Usados',
        value:0
    }

]

export const publicationDate:DataOptionsTypes[]=[    
    {
        text:'Hoy',
        value:0
    },
    {
        text:'Desde ayer',
        value:1
    },
    {
        text:'Última Semana',
        value:7
    },
    {
        text:'Últimos 15 días',
        value:15
    },
    {
        text:'Últimos 30 días',
        value:30
    },
    {
        text:'Últimos 40 días',
        value:40
    }
]

export const seaDistance:DataOptionsTypes[]=[
    {
        text:'Frente al mar',
        value:1
    },
    // {
    //     text:'Menos de 100 m',
    //     value:2
    // },
    // {
    //     text: '200 m',
    //     value: 3
    // },
    // {
    //     text: '300 m',
    //     value: 4
    // },
    // {
    //     text: '400 m',
    //     value: 5
    // },
    // {
    //     text: '500 m',
    //     value: 6
    // },
    {
        text: 'Menos de 1.000 m',
        value: 7
    },
    // {
    //     text: 'Más de 1000 m',
    //     value: 8
    // }
]

export const buildingFloor:DataOptionsTypes[]=[
    {
        text:'Planta Baja',
        value:0
    },
    {
        text:'1ro al 5to piso',
        value:1
    },
    {
        text:'6to al 10mo piso',
        value:2
    },
    {
        text:'+10mo piso',
        value:3
    },
    {
        text:'Penthouse',
        value:4
    }
]

export const dispositionFilter:DataOptionsTypes[]=[
    {
        text:'No aplica',
        value:1        
    },
    {
        text:'Al frente',
        value:2
    },
    {
        text:'Contrafrente',
        value:3
    },
    {
        text:'Interior',
        value:4
    },
    {
        text:'Lateral',
        value:5
    }
]

export const seasonsFilters:DataOptionsTypes[]=[
    {
        text:'Primera Quincena Diciembre',
        value:'pqdiciembre',        
    },
    {
        text:'Segunda Quincena Diciembre',
        value:'sqdiciembre',        
    },
    {
        text:'Diciembre',
        value:'diciembre',        
    },
    {
        text:'Primera Quincena Enero',
        value:'pqenero',        
    },
    {
        text:'Segunda Quincena Enero',
        value:'sqenero',        
    },
    {
        text:'Enero',
        value:'enero',        
    },
    {
        text:'Primera Quincena Febrero',
        value:'pqfebrero',        
    },
    {
        text:'Segunda Quincena Febrero',
        value:'sqfebrero',        
    },
    {
        text:'Febrero',
        value:'febrero',        
    },
    {
        text:'Réveillon',
        value:'reveillon',        
    },
    {
        text:'Semana Santa',
        value:'semanasanta',        
    },
    {
        text:'Carnaval',
        value:'carnaval',        
    }
]